import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { NotificationsComponent } from '@app/shared/layout/notifications/notifications.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { WelcomeComponent } from './shared/layout/welcome/welcome.component';

const routes: Routes = [
    {
        path: 'app',
        component: AppComponent,
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        children: [
            {
                path: '',
                children: [
                    { path: 'notifications', component: NotificationsComponent },
                    { path: 'welcome', component: WelcomeComponent },
                    { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                ],
            },
            {
                path: 'guideline-ui',
                loadChildren: () => import('./guideline-ui/guideline-ui.module').then((m) => m.GuidelineUIModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'main',
                loadChildren: () => import('./main/main.module').then((m) => m.MainModule), // Lazy load main module
                data: { preload: true },
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'host-message-manager',
                loadChildren: () => import('./pages/host-app/quan-ly-tin-nhan/quan-ly-tin-nhan.module').then(m => m.HostQuanLyTinNhanModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'tenant-dashboard',
                loadChildren: () => import('./pages/tenant-app/dashboard/tenant-dashboard.module').then((m) => m.TenantDashBoardModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'mytel-price-policy',
                loadChildren: () => import('./pages/host-app/chinh-sach-gia-mytel/chinh-sach-gia-mytel.module').then((m) => m.ChinhSachGiaMyTelModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'category',
                loadChildren: () => import('./pages/host-app/danh-muc/danh-muc.module').then((m) => m.DanhMucModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'manage-customers',
                loadChildren: () =>
                    import('./pages/host-app/customer/customer.module').then((m) => m.CustomerModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'manage-agency',
                loadChildren: () => import('./pages/host-app/agency/agency.module').then((m) => m.AgencyModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'deposit-management',
                loadChildren: () => import('./pages/host-app/quan-ly-nap-tien/quan-ly-nap-tien.module').then((m) => m.QuanLyNapTienModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'manage-brand-name',
                loadChildren: () =>
                    import('./pages/host-app/quan-ly-brand-name/quan-ly-brand-name.module').then((m) => m.QuanLyBrandNameModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'brand-name',
                loadChildren: () => import('./pages/tenant-app/brand-name/brand-name.module').then((m) => m.BrandNameModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'contact',
                loadChildren: () => import('./pages/tenant-app/danh-ba/danh-ba.module').then((m) => m.DanhBaModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'message',
                loadChildren: () => import('./pages/tenant-app/nhan-tin/nhan-tin.module').then((m) => m.NhanTinModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'account-balance',
                loadChildren: () =>
                    import('./pages/tenant-app/quan-ly-so-du-tai-khoan/quan-ly-so-du-tai-khoan.module').then(
                        (m) => m.QuanLySoDuTaiKhoanModule,
                    ), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'message-package',
                loadChildren: () => import('./pages/tenant-app/goi-tin-nhan/goi-tin-nhan.module').then((m) => m.GoiTinNhanModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },

            {
                path: 'report',
                loadChildren: () => import('./pages/host-app/bao-cao/bao-cao.module').then((m) => m.BaoCaoModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'contract-manage',
                loadChildren: () => import('./pages/host-app/contract-manage/contract-manage.module').then((m) => m.ContractManageModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'notification-to-tenant',
                loadChildren: () => import('./pages/host-app/thong-bao/thong-bao.module').then(m => m.ThongBaoModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'purchased-order',
                loadChildren: () => import('./pages/host-app/purchased-order/purchased-order.module').then(m => m.PurchasedOrderModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'tenant-report',
                loadChildren: () => import('./pages/tenant-app/bao-cao/bao-cao.module').then(m => m.Tenant_BaoCaoModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
           
            {
                path: 'blacklist-contact',
                loadChildren: () => import('./pages/tenant-app/blacklist-contact/blacklist-contact.module').then((m) => m.BlacklistContactModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'tenant-settings',
                loadChildren: () => import('./pages/tenant-app/settings/settings.module').then((m) => m.SettingsModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'ipwhitelist',
                loadChildren: () => import('./pages/tenant-app/ipwhitelist/ipwhitelist.module').then((m) => m.IpWhiteListModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'postpaid-billing',
                loadChildren: () => import('./pages/host-app/postpaid-billing/postpaid-billing.module').then(m => m.PostPaidBillingModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            { path: '**', redirectTo: '/app/main/dashboard' },
        ],
    },
];

@NgModule({
    imports: [
        //RouterModule.forChild(routes)]
        RouterModule.forRoot(routes, {
            useHash: false,
            // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
            // Pls refer to https://ng-alain.com/components/reuse-tab
            // scrollPositionRestoration: 'top',
            scrollPositionRestoration: 'disabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router) {}
}

<form nz-form #twoFactorForm='ngForm' method='post' novalidate (ngSubmit)='submit()' role='form'>
    <nz-alert class='mb-3' nzType='info' nzCloseable [nzMessage]="messageTemplate" nzShowIcon>
        <ng-template #messageTemplate>
            <div>Verification code has been sent email <strong>{{emailVerify}}</strong>. Please check and enter it below</div>
        </ng-template>
    </nz-alert> 
    <nz-form-item>
        <nz-form-control [nzErrorTip]='passwordValidationTpl'>
            <input nz-input #passwordInput='ngModel' 
                    autoFocus [(ngModel)]='code' type='text' autocomplete='off'
                   placeholder="{{'Code' | localize}}" name='code' required maxlength='16' id='code' />
            <ng-template #passwordValidationTpl let-control>
                <validation-messages [formCtrl]='control'></validation-messages>
            </ng-template>
        </nz-form-control>
    </nz-form-item>
    <div class="text-center mt-2">
        Your code is expired in <strong>{{countdown}}</strong> second(s)
    </div>
</form>
<div *nzModalFooter>
    <button type='button' nz-button nzType='default' (click)='close()'>
        <span nz-icon nzType='close' nzTheme='outline'></span>{{'Close' | localize}}
    </button>
    <button nz-button nzType='primary' nzSize='default' type='submit' (click)='submit()'
            [disabled]='!twoFactorForm.form.valid'>
            <span nz-icon nzType="check-circle" nzTheme="outline"></span>
            {{"Verify" | localize}}</button>
</div>

<div class="alain-default__aside-inner">
    <sidebar-nav class="d-block"></sidebar-nav>
    <!-- <sidebar-nav class="d-block py-lg"></sidebar-nav> -->

    <div *ngIf="appSession.tenant && !collapsed" class="countSent" >
        <nz-skeleton [nzLoading]="loading" [nzActive]="true">
            <div class="title-table">{{'SoSMSConLai' | localize}}</div>
        <ul id="remain-sms" style="padding-left: 20px;">
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> Mytel: </span> 
                    <span *ngIf="appSession.tenant.myTelRemaining == null">Unlimlited</span>
                    <span *ngIf="appSession.tenant.myTelRemaining != null">{{appSession.tenant.myTelRemaining | number}}</span>
                </span>
            </li>
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> MPT: </span>
                    <span *ngIf="appSession.tenant.mptRemaining == null">Unlimlited </span>
                    <span *ngIf="!appSession.tenant.mptRemaining != null">{{appSession.tenant.mptRemaining | number}}</span>
                </span>
            </li>
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> Telenor: </span> 
                    <span *ngIf="appSession.tenant.telenorRemaining == null">Unlimlited </span>
                    <span *ngIf="!appSession.tenant.telenorRemaining != null">{{appSession.tenant.telenorRemaining | number}}</span>
                </span>
            </li>
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> Ooredoo: </span> 
                    <span *ngIf="appSession.tenant.ooredooRemaining == null">Unlimlited</span>
                    <span *ngIf="appSession.tenant.ooredooRemaining != null">{{appSession.tenant.ooredooRemaining | number}}</span>
                </span>
            </li>
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> Mectel: </span> 
                    <span *ngIf="appSession.tenant.mectelRemaining == null">Unlimlited</span>
                    <span *ngIf="appSession.tenant.mectelRemaining != null">{{appSession.tenant.mectelRemaining | number}}</span>
                </span>
            </li>
            <li>
                <span class="ml-1">
                    <span class="font-weight-bold"> ATOM: </span> 
                    <span *ngIf="appSession.tenant.atomRemaining == null">Unlimited</span>
                    <span *ngIf="appSession.tenant.atomRemaining != null">{{appSession.tenant.atomRemaining | number}}</span>
                </span>
            </li>
        </ul>
        </nz-skeleton>
        
        
        <!-- <div *ngIf="!appSession.tenant.myTelRemaining && !appSession.tenant.mptRemaining && !appSession.tenant.telenorRemaining && !appSession.tenant.ooredooRemaining && !appSession.tenant.mectelRemaining">
            <span>{{ 'YouHave0MessageLeft' | localize }}</span>
                <button nz-button nzType="link" (click)="gotoUrl('app/message-package/buy-message')">
                    {{'ClickHereToBuy' | localize}}
                </button>
        </div> -->
    </div>
</div>

<head>
    <link href='https://cdn.jsdelivr.net/npm/@goongmaps/goong-js@1.0.9/dist/goong-js.css' rel='stylesheet' />
</head>
<div class="alain-default__header-logo">
    <a class="mobile-menu-toggle" (click)="toggleMenu.emit(!isToggleMenu)">
        <i nz-icon nzType="menu" nzTheme="outline"></i>
    </a>
    <a target="_blank" class="alain-default__header-logo-link">
        <img class="alain-default__header-logo-expanded" src="{{ logoImage }}" alt="{{ settings.app.name }}" style="max-height: 40px" />
    </a>
</div>
<div class="alain-default__nav-wrap">
    <ul class="alain-default__nav">
        <li style="font-weight: bold; text-transform: uppercase">
            <h1 class="title-he-thong" style="">
                <span *ngIf="this.appSession.tenant">{{ this.appSession.tenant.name }}</span>
                <span *ngIf="!this.appSession.tenant">{{ 'HomeTitle' | localize }}</span>
            </h1>
        </li>
    </ul>
    <ul class="alain-default__nav">
        <!-- <li class="nav-currency__button" *ngIf="appSession?.tenant" (click)="gotoUrl('/app/account-balance/select-menu-account-balance')">
            <span nz-icon nzType="dollar" nzTheme="outline"></span>
            <a routerLink="/app/account-balance/select-menu-account-balance">{{ 'Pages_QuanLySoDuTaiKhoan_NapTien' | localize }}</a>
        </li>
        <li class="nav-currency__text mr-2" *ngIf="appSession?.tenant" (click)="getBalanceAccount()">
            <ng-container>
                <nz-badge [nzCount]="iconTemplate">
                    <span class="head-example">{{ appSession?.tenant?.balanceAccount | number }} {{currencySign}}</span>
                </nz-badge>
                <ng-template #iconTemplate>
                    <a href="javascript:void(0)" nz-tooltip="{{ 'SoTienMoiNhat' | localize }}" nzTooltipPlacement="bottom"><span nz-icon nzType="retweet" class="ant-scroll-number-custom-component" style="color: #aaa1a2; right: -10px;"  ></span></a>
                </ng-template>
            </ng-container>
        </li> -->

        <li *ngIf="isImpersonatedLogin">
            <button (click)="backToMyAccount()" nz-button nzType="link" title="{{ 'BackToMyAccount' | localize }}">
                <i nz-icon nzType="api" nzTheme="outline"></i>
            </button>
        </li>
        <li class="hidden-mobile">
            <header-notifications style="float: left; padding-top: 2px"></header-notifications>
            <language-switch-v2 style="float: left; margin-right: 12px; padding-top: 4px"></language-switch-v2>
            <header-user style="float: left"></header-user>
        </li>
    </ul>
</div>



import { Component, Input, OnInit } from '@angular/core';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { Injector } from '@node_modules/@angular/core';
import { CommonResultDtoOfBoolean, TwoFactorAuthenticationServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from '@node_modules/rxjs/operators';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'validate-two-factor-code',
  templateUrl: './validate-two-factor-code.component.html'
})
export class ValidateTwoFactorCodeComponent extends ModalComponentBase implements OnInit {
    code = '';

    @Input() expireIn;

    @Input() emailVerify;

    countdown = 0;

    countdownSubscription!: Subscription;

    constructor(injector: Injector,
                private _twoFactorAuthenticationService: TwoFactorAuthenticationServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
        setTimeout(() => {
            document.getElementById('code').focus();
        }, 300);
        this.countdown = this.expireIn;
        this.startCountdown();
    }

    startCountdown() {
        this.countdownSubscription = interval(1000).subscribe(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } 
          else {
            this.countdownSubscription.unsubscribe();
            this.onCountdownComplete(); 
          }
        });
    }

    onCountdownComplete() {
        this.close();
    }

    submit() {
        abp.ui.setBusy();
        this._twoFactorAuthenticationService.verifyTwoFactorAuthCode(this.code).pipe((finalize(() => {
            abp.ui.clearBusy();
        }))).subscribe((result: CommonResultDtoOfBoolean) => {
            if (result.isSuccessful) {
                this.success(true);
            } else {
                abp.notify.error("Your code is incorrect", this.l('VerifyCodeFailed'));
            }
        });
    }

    ngOnDestroy(): void {
        if (this.countdownSubscription) {
          this.countdownSubscription.unsubscribe(); 
        }
    }
}

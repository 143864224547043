import { AbpMultiTenancyService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { ApplicationInfoDto, GetCurrentLoginInformationsOutput, SessionServiceProxy, TenantLoginInfoDto, UiCustomizationSettingsDto, UserLoginInfoDto } from '@shared/service-proxies/service-proxies';

@Injectable()
export class AppSessionService {

    private _user: UserLoginInfoDto;
    private _tenant: TenantLoginInfoDto;
    private _application: ApplicationInfoDto;
    private _theme: UiCustomizationSettingsDto;
    private _sesionService: SessionServiceProxy;
    constructor(
        private _sessionService: SessionServiceProxy,
        private _abpMultiTenancyService: AbpMultiTenancyService) {
    }

    get application(): ApplicationInfoDto {
        return this._application;
    }

    get user(): UserLoginInfoDto {
        return this._user;
    }

    get userId(): number {
        return this.user ? this.user.id : null;
    }

    get tenant(): TenantLoginInfoDto {
        return this._tenant;
    }

    get tenancyName(): string {
        return this._tenant ? this.tenant.tenancyName : '';
    }

    get tenantId(): number {
        return this.tenant ? this.tenant.id : null;
    }

    get theme(): UiCustomizationSettingsDto {
        return this._theme;
    }

    set theme(val: UiCustomizationSettingsDto) {
        this._theme = val;
    }
    getShownLoginName(): string {
        const userName = this._user?.userName;
        if (!this._abpMultiTenancyService.isEnabled) {
            return userName;
        }
        return userName;
        //return (this._tenant ? this._tenant.tenancyName : '.') + '\\' + userName;
    }

    getBalanceAccount(){
        this._sessionService.getBalanceAccount().subscribe(result=>{
            this.tenant.balanceAccount =  result.result;
        });
    }

    getCountSMS(callbackFunc){
        this._sessionService.getSMSAccount(undefined).subscribe(result=>{
            let data =  result.result;
            this.tenant.myTelRemaining =  data.myTelRemainingSMS;
            this.tenant.mptRemaining =  data.mptRemainingSMS;
            this.tenant.telenorRemaining =  data.telenorRemainingSMS;
            this.tenant.ooredooRemaining =  data.ooredooRemainingSMS;
            this.tenant.mectelRemaining =  data.mectelRemainingSMS;
            this.tenant.atomRemaining =  data.atomRemainingSMS;
            if(callbackFunc){
                callbackFunc();
            }
        });
    }

    init(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._sessionService.getCurrentLoginInformations().toPromise().then((result: GetCurrentLoginInformationsOutput) => {
                this._application = result.application;
                this._user = result.user;
                this._tenant = result.tenant;
                resolve(true);
            }, (err) => {
                reject(err);
            });
        });
    }

    changeTenantIfNeeded(tenantId?: number): boolean {
        if (this.isCurrentTenant(tenantId)) {
            return false;
        }

        abp.multiTenancy.setTenantIdCookie(tenantId);
        location.reload();
        return true;
    }

    private isCurrentTenant(tenantId?: number) {
        let isTenant = tenantId > 0;

        if (!isTenant && !this.tenant) { // this is host
            return true;
        }

        if (!tenantId && this.tenant) {
            return false;
        } else if (tenantId && (!this.tenant || this.tenant.id !== tenantId)) {
            return false;
        }

        return true;
    }
}
